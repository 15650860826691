.sections {
  width: 100%;
  height: auto;
  background: linear-gradient(0deg, #1F2B38 0%, #3C5670 100%);
  overflow: hidden;
}

.sections-inner {
  position: relative;
  margin: 0 auto;
  width: 80%;
  height: 100%;
  border-radius: 8px;
  background: #eee;
  box-shadow: 0px 2px 10px 0px #1F2B38;
}

@media only screen and (max-width: 1048px) {
  .sections-inner {
    width: 100% !important;
  }
}

.copyright {
  font-size: 16px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}

.kontys-out {
  font-size: 2px;
  opacity: 0.2;
  color: #fff;
  bottom:0;
  left: 0;
  margin: 0;
}