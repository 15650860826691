.header-title {
  font-size: 30px;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 20px;
  color: #fff;
  animation: header-title-entry 0.5s ease-in-out forwards;
}

@keyframes header-title-entry {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-container {
  overflow: hidden;
  height: 100vh;
  background: #3C5670;
  min-height: -webkit-fill-available;
}

.nav {
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 10px;
  top: 0px;
  left: 0px;
  width: 100%;
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logos a {
  display: block;
}

.logos img {
  height: 60px;
}

.social img {
  height: 40px;
}

.logos img:nth-child(2) {
  margin-left: 20px;
  height: 18px;
}

.down-button {
  position: absolute;
  bottom: 20px;
  width: 300px;
  border: none;
  height: 80px;
  background: transparent;
  border: none;
  left: calc(50% - 150px);
  cursor: pointer;
  z-index: 9999;
}

.down-button img {
  width: 50px;
  animation: downBob 1s linear forwards infinite;
}

@keyframes downBob {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}