.section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.section-title {
    margin: 0;
    font-size: 26px;
    font-weight: 700;
    color: #333;
}

.section-content {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.section-content {
    color: #333;
}